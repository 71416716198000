import { useDispatch, useStore } from '@globalStore/storeProvider';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { sourceType } from './ContactUs';

import dynamic from 'next/dynamic';

import { DemoClassFormWithCloseBtn } from '@components/local/courses/forms/demo-class-form/DemoClassFormButton';
import GlobalBrandingHeader from '../forms/global-form/GlobalBrandingHeader';
const ContactUs = dynamic(() => import('@shared/widgets/ContactUs'));

function ContactModal({ showRandromizePopup = true }) {
  const { widgetSource } = useStore();
  const dispatch = useDispatch();
  const router = useRouter();

  const [openDemoClassPopup, setOpenDemoClassPopup] = useState(false);

  const isDemoClassPopup = useMemo(() => Math.random() < 0.5, []);

  const openPopup = () => {
    isDemoClassPopup && showRandromizePopup
      ? setOpenDemoClassPopup(true)
      : dispatch({ type: 'SET_WIDGET_SOURCE', payload: sourceType.popup });
  };

  let hidePopups = false;

  const hideRCBWidget =
    router.pathname?.startsWith('/bytes') || router.pathname?.startsWith?.('/events');

  if (
    router.pathname?.startsWith('/degrees') ||
    router.pathname?.startsWith('/placement-statistics') ||
    router.pathname?.startsWith('/community') ||
    hideRCBWidget
  ) {
    hidePopups = true;
  }

  useEffect(() => {
    let timerId = null;
    if (widgetSource || openDemoClassPopup) {
      sessionStorage.setItem('popup_viewed', true);
      return;
    }

    if (hidePopups) {
      clearTimeout(timerId);
      return;
    }

    timerId = setTimeout(() => {
      const isPopupViewed = sessionStorage.getItem('popup_viewed');

      if (!isPopupViewed && !widgetSource) {
        openPopup();
      }
    }, 15000);

    return () => {
      clearTimeout(timerId);
    };
  }, [router, widgetSource, dispatch, openDemoClassPopup]);

  return (
    <>
      {openDemoClassPopup ? (
        <DemoClassPopUp closePopup={() => setOpenDemoClassPopup(false)} />
      ) : (
        <ContactUs isBytesPage={hideRCBWidget} />
      )}
    </>
  );
}

export default ContactModal;

function DemoClassPopUp({ closePopup }) {
  return (
    <div
      className="fixed inset-0 z-[1111] flex items-center justify-center bg-black bg-opacity-20 backdrop-blur-sm"
      onClick={closePopup}
    >
      <div className="relative flex w-full items-center justify-center px-[16px]">
        <div className="md:w-2/6" onClick={(e) => e.stopPropagation()}>
          <GlobalBrandingHeader closeModal={closePopup}>
            <DemoClassFormWithCloseBtn formStyle="md:max-h-[52vh] h-fit slim-scroll" />
          </GlobalBrandingHeader>
        </div>
      </div>
    </div>
  );
}
